import { useState } from 'react';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { OpinionsAnalyzer } from './components/OpinionsAnalyzer';
import { LoginPage }   from './components/LoginPage';
import { theme } from './styles/theme';

// Importy CSS
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import './styles/global.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState('');

  const handleLogin = (token: string) => {
    setIsLoggedIn(true);
    setToken(token);
    localStorage.setItem('token', token);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setToken('');
    localStorage.removeItem('token');
  };

  return (
    <MantineProvider theme={theme}>
      <div style={{
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #0A0A0A 0%, #1A1A1A 100%)',
      }}>
        <Notifications />
        {!isLoggedIn ? (
          <LoginPage onLogin={handleLogin} />
        ) : (
          <OpinionsAnalyzer onLogout={handleLogout} token={token} />
        )}
      </div>
    </MantineProvider>
  );
}

export default App; 