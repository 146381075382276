import { createTheme } from '@mantine/core';

export const theme = createTheme({
  primaryColor: 'dark',
  defaultRadius: 'md',
  colors: {
    dark: [
      '#C1C2C5',
      '#A6A7AB',
      '#909296',
      '#5C5F66',
      '#373A40',
      '#2C2E33',
      '#25262B',
      '#1A1B1E',
      '#141517',
      '#101113',
    ],
    gold: [
      '#FFF9E5',
      '#FFE5B2',
      '#FFD280',
      '#FFBE4D',
      '#FFAA1A',
      '#FF9500',
      '#E68600',
      '#CC7700',
      '#B36800',
      '#995900',
    ],
  },
  components: {
    Text: {
      styles: {
        root: {
          lineHeight: 1.6
        }
      }
    },
    Paper: {
      defaultProps: {
        p: 'xl',
        radius: 'md',
        withBorder: true,
      },
      styles: {
        root: {
          backgroundColor: '#1A1B1E',
          borderColor: 'rgba(255, 215, 0, 0.1)',
          '&.opinions-table': {
            height: 'calc(100vh - 200px)',
            marginBottom: 'xl',
            padding: 'md'
          }
        }
      }
    },
    Container: {
      defaultProps: {
        size: 'xl',
      },
      styles: {
        root: {
          padding: '40px',
        }
      }
    },
    Button: {
      defaultProps: {
        size: 'md',
        radius: 'md',
      },
      styles: {
        root: {
          fontFamily: 'Montserrat, sans-serif',
          fontWeight: 600,
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-2px)',
          }
        }
      }
    },
    Select: {
      defaultProps: {
        radius: 'md',
      },
      styles: {
        input: {
          backgroundColor: '#25262B',
          borderColor: 'rgba(255, 215, 0, 0.1)',
          color: '#FFFFFF',
          '&::placeholder': {
            color: 'rgba(255, 255, 255, 0.5)'
          }
        },
        dropdown: {
          backgroundColor: '#25262B',
          borderColor: 'rgba(255, 215, 0, 0.1)',
          zIndex: 1000
        },
        option: {
          color: '#FFFFFF',
          '&[data-selected]': {
            '&, &:hover': {
              backgroundColor: 'rgba(255, 215, 0, 0.1)',
              color: '#FFFFFF',
            }
          }
        },
        label: {
          color: '#FFFFFF'
        }
      }
    },
    TextInput: {
      styles: {
        input: {
          color: '#FFFFFF',
          backgroundColor: '#25262B',
          borderColor: 'rgba(255, 215, 0, 0.1)',
          '&::placeholder': {
            color: 'rgba(255, 255, 255, 0.5)'
          }
        },
        label: {
          color: '#FFFFFF'
        }
      }
    },
    PasswordInput: {
      styles: {
        input: {
          color: '#FFFFFF',
          backgroundColor: '#25262B',
          borderColor: 'rgba(255, 215, 0, 0.1)',
          '&::placeholder': {
            color: 'rgba(255, 255, 255, 0.5)'
          }
        },
        label: {
          color: '#FFFFFF'
        }
      }
    },
    DateInput: {
      styles: {
        input: {
          color: '#FFFFFF',
          backgroundColor: '#25262B',
          borderColor: 'rgba(255, 215, 0, 0.1)',
          '&::placeholder': {
            color: 'rgba(255, 255, 255, 0.5)'
          }
        },
        label: {
          color: '#FFFFFF'
        }
      }
    },
    ScrollArea: {
      styles: {
        root: {
          '&.opinions-table': {
            height: 'calc(100vh - 300px)'
          }
        }
      }
    },
    Table: {
      styles: {
        root: {
          border: '1px solid rgba(255, 255, 255, 0.1)',
          color: '#FFFFFF',
          '& td, & th': {
            padding: '16px',
            verticalAlign: 'middle',
            color: 'inherit'
          },
          '& thead th': {
            fontWeight: 600,
            backgroundColor: '#1A1B1E',
            position: 'sticky',
            top: 0,
            zIndex: 2,
            color: '#FFD700 !important'
          },
          '& tbody': {
            color: '#FFFFFF',
            '& tr': {
              height: '80px',
              transition: 'background-color 0.2s ease',
              cursor: 'pointer',
              borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
              '&:hover': {
                backgroundColor: 'rgba(255, 215, 0, 0.05)'
              },
              '&.active': {
                backgroundColor: 'rgba(255, 215, 0, 0.1)'
              },
              '&.has-issue': {
                backgroundColor: 'rgba(255, 215, 0, 0.05)'
              }
            },
            '& td': {
              color: '#FFFFFF !important',
              '&.date': {
                width: '150px',
                whiteSpace: 'nowrap',
                color: '#FFD700 !important'
              },
              '&.comment': {
                width: '40%',
                lineHeight: 1.5
              },
              '&.network': {
                width: '100px',
                textAlign: 'center',
                color: '#A6A7AB !important'
              },
              '&.issue-type': {
                width: '350px',
                '& .mantine-Select-input': {
                  color: '#FFFFFF !important'
                }
              },
              '&.qa-status': {
                width: '150px',
                '& .mantine-Select-input': {
                  color: '#FFFFFF !important'
                }
              }
            }
          }
        }
      }
    }
  }
}); 