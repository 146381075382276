import React from 'react';
import { Opinion } from '../types/Opinion';
import { qaStatusLabels, QAStatus } from '../data/qaStatuses';

interface OpinionsStatsProps {
  opinions: Opinion[];
  startDate: Date;
  endDate: Date;
}

export const OpinionsStats: React.FC<OpinionsStatsProps> = ({ 
  opinions, 
  startDate, 
  endDate 
}) => {
  // Obliczanie statystyk
  const totalOpinions = opinions.length;
  
  // Statystyki QA
  const qaStats = opinions.reduce((acc, op) => {
    const status = op.qa_status || 'Brak statusu';
    acc[status] = (acc[status] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  // Statystyki typów problemów
  const issueTypes = opinions.reduce((acc, op) => {
    const type = op.issue_type || 'Nieokreślony';
    acc[type] = (acc[type] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  // Formatowanie daty
  const formatDate = (date: Date) => {
    return date.toLocaleDateString('pl-PL', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <div className="content">
      <h2 className="title is-4">Statystyki opinii</h2>
      <div className="columns is-multiline">
        <div className="column is-12">
          <div className="box">
            <h3 className="title is-5">Podstawowe informacje</h3>
            <p><strong>Łączna liczba opinii:</strong> {totalOpinions}</p>
            <p><strong>Okres analizy:</strong> {formatDate(startDate)} - {formatDate(endDate)}</p>
          </div>
        </div>

        <div className="column is-6">
          <div className="box">
            <h3 className="title is-5">Status QA</h3>
            <table className="table is-fullwidth is-striped">
              <thead>
                <tr>
                  <th>Status</th>
                  <th className="has-text-right">Liczba</th>
                  <th className="has-text-right">Udział</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(qaStats).map(([status, count]) => (
                  <tr key={status}>
                    <td>{qaStatusLabels[status as QAStatus] || status}</td>
                    <td className="has-text-right">{count}</td>
                    <td className="has-text-right">
                      {((count / totalOpinions) * 100).toFixed(1)}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="column is-6">
          <div className="box">
            <h3 className="title is-5">Typy problemów</h3>
            <table className="table is-fullwidth is-striped">
              <thead>
                <tr>
                  <th>Typ problemu</th>
                  <th className="has-text-right">Liczba</th>
                  <th className="has-text-right">Udział</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(issueTypes)
                  .sort(([, a], [, b]) => b - a) // Sortowanie po liczbie wystąpień
                  .map(([type, count]) => (
                    <tr key={type}>
                      <td>{type}</td>
                      <td className="has-text-right">{count}</td>
                      <td className="has-text-right">
                        {((count / totalOpinions) * 100).toFixed(1)}%
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}; 