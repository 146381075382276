import React, { useState } from 'react';
import axios from 'axios';
import { notifications } from '@mantine/notifications';

interface LoginPageProps {
  onLogin: (token: string) => void;
}

export const LoginPage: React.FC<LoginPageProps> = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${import.meta.env.VITE_API_URL}/auth/login`, {
        username,
        password
      });
      
      if (response.data.token) {
        onLogin(response.data.token);
      }
    } catch (error) {
      console.error('Login failed:', error);
      notifications.show({
        title: 'Błąd logowania',
        message: 'Nieprawidłowe dane logowania',
        color: 'red'
      });
    }
  };

  return (
    <section className="hero is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5-tablet is-4-desktop is-3-widescreen">
              <div className="box">
                <h1 className="title has-text-centered has-text-primary">
                  Analizator Opinii
                </h1>
                <h2 className="subtitle has-text-centered is-6 mb-4">
                  Panel logowania
                </h2>

                {error && (
                  <div className="notification is-danger is-light">
                    <button className="delete" onClick={() => setError('')}></button>
                    {error}
                  </div>
                )}

                <form onSubmit={handleSubmit}>
                  <div className="field">
                    <label className="label">Email</label>
                    <div className="control has-icons-left">
                      <input
                        className={`input ${error ? 'is-danger' : ''}`}
                        type="email"
                        placeholder="np. robert@example.com"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-envelope"></i>
                      </span>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Hasło</label>
                    <div className="control has-icons-left">
                      <input
                        className={`input ${error ? 'is-danger' : ''}`}
                        type="password"
                        placeholder="********"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-lock"></i>
                      </span>
                    </div>
                  </div>

                  <div className="field mt-5">
                    <button
                      type="submit"
                      className="button is-primary is-fullwidth"
                    >
                      Zaloguj się
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}; 