import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { OpinionsStats } from './OpinionsStats';
import axios from 'axios';
import { notifications } from '@mantine/notifications';
import { Opinion } from '../types/Opinion';
import { qaStatusLabels, QAStatus } from '../data/qaStatuses.ts';

interface OpinionsAnalyzerProps {
  onLogout: () => void;
  token: string;
}

export const OpinionsAnalyzer: React.FC<OpinionsAnalyzerProps> = ({ onLogout, token }) => {
  const [opinions, setOpinions] = useState<Opinion[]>([]);
  const [activeTab, setActiveTab] = useState<'opinions' | 'stats'>('opinions');
  const [startDate, setStartDate] = useState<Date>(() => {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    date.setHours(0, 0, 0, 0);
    return date;
  });
  const [endDate, setEndDate] = useState<Date>(() => {
    const date = new Date();
    date.setHours(23, 59, 59, 999);
    return date;
  });
  const [selectedIssueType, setSelectedIssueType] = useState<string>('');
  const [isPremium, setIsPremium] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedQAStatus, setSelectedQAStatus] = useState<QAStatus | ''>('');

  const loadOpinions = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${import.meta.env.VITE_API_URL}/opinions`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (response.data && Array.isArray(response.data)) {
        setOpinions(response.data);
      }
    } catch (error) {
      console.error('Failed to load opinions:', error);
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        onLogout();
        notifications.show({
          title: 'Błąd autoryzacji',
          message: 'Sesja wygasła. Zaloguj się ponownie.',
          color: 'red'
        });
      } else {
        notifications.show({
          title: 'Błąd',
          message: 'Nie udało się wczytać opinii',
          color: 'red'
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadOpinions();
  }, [startDate, endDate]);

  const filteredOpinions = useMemo(() => {
    return opinions
      .filter(opinion => {
        const opinionDate = new Date(opinion.rated_at);
        return (
          opinionDate >= startDate &&
          opinionDate <= endDate &&
          (!selectedIssueType || opinion.issue_type === selectedIssueType) &&
          (!selectedQAStatus || opinion.qa_status === selectedQAStatus)
        );
      })
      .sort((a, b) => {
        const dateA = new Date(a.rated_at);
        const dateB = new Date(b.rated_at);
        const comparison = dateB.getTime() - dateA.getTime();
        return comparison;
      });
  }, [opinions, startDate, endDate, selectedIssueType, selectedQAStatus]);

  const handleDateChange = (date: Date | null, isStartDate: boolean) => {
    if (isStartDate) {
      setStartDate(date || new Date('2024-10-01'));
    } else {
      setEndDate(date || new Date());
    }
  };

  const handleUpdateOpinion = useCallback(async (index: number, issueType: string) => {
    console.log('handleUpdateOpinion:', { index, issueType });
    try {
      const opinion = filteredOpinions[index];
      const originalIndex = opinions.findIndex(op => 
        op.rated_at === opinion.rated_at && 
        op.rate_comment === opinion.rate_comment &&
        op.network_id === opinion.network_id
      );

      if (originalIndex === -1) {
        console.error('Nie znaleziono opinii:', { index, issueType, opinion });
        throw new Error('Nie znaleziono opinii');
      }

      setOpinions(prev => {
        const updated = [...prev];
        updated[originalIndex] = {
          ...updated[originalIndex],
          issue_type: issueType
        };
        return updated;
      });

      const response = await axios.post(`${import.meta.env.VITE_API_URL}/opinions/update`, {
        index: originalIndex,
        issueType
      });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Nie udało się zaktualizować opinii');
      }

      notifications.show({
        title: 'Sukces',
        message: response.data.message || 'Opinia została zaktualizowana',
        color: 'green'
      });
    } catch (error: any) {
      console.error('Failed to update opinion:', error);
      notifications.show({
        title: 'Błąd',
        message: error.response?.data?.message || error.message || 'Nie udało się zaktualizować opinii',
        color: 'red'
      });
    }
  }, [filteredOpinions, opinions]);

  const handleUpdateQAStatus = async (index: number, status: QAStatus) => {
    try {
      const opinion = filteredOpinions[index];
      const originalIndex = opinions.findIndex(op => 
        op.rated_at === opinion.rated_at && 
        op.rate_comment === opinion.rate_comment &&
        op.network_id === opinion.network_id
      );

      if (originalIndex === -1) {
        throw new Error('Nie znaleziono opinii');
      }

      const updatedOpinions = [...opinions];
      updatedOpinions[originalIndex] = {
        ...updatedOpinions[originalIndex],
        qa_status: status
      };

      const response = await axios.post(`${import.meta.env.VITE_API_URL}/opinions/update-qa-status`, {
        index: originalIndex,
        qaStatus: status
      });

      if (response.data.success) {
        setOpinions(updatedOpinions);
        notifications.show({
          title: 'Sukces',
          message: 'Status QA został zaktualizowany',
          color: 'green'
        });
      }
    } catch (error) {
      console.error('Failed to update QA status:', error);
      notifications.show({
        title: 'Błąd',
        message: 'Nie udało się zaktualizować statusu QA',
        color: 'red'
      });
    }
  };

  const handlePurchasePremium = () => {
    const confirmPurchase = window.confirm(
      'Czy na pewno chcesz kupić wersję Premium za $1,000,000? ' +
      'Ta operacja jest nieodwracalna i nie podlega zwrotom.'
    );
    
    if (confirmPurchase) {
      alert('Dziękujemy za zakup! Twoje konto zostało zaktualizowane do wersji Premium.');
      setIsPremium(true);
    }
  };

  if (isLoading) {
    return (
      <div className="container">
        <div className="box has-text-centered">
          <p>Ładowanie opinii...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <h1 className="navbar-item title">Analizator Opinii</h1>
        </div>
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              {!isPremium && (
                <button 
                  className="button is-primary" 
                  onClick={handlePurchasePremium}
                >
                  Kup Premium
                </button>
              )}
              <button 
                className="button is-light" 
                onClick={onLogout}
              >
                Wyloguj
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="tabs is-boxed mb-4">
        <ul>
          <li className={activeTab === 'opinions' ? 'is-active' : ''}>
            <a onClick={() => setActiveTab('opinions')}>
              <span>Opinie</span>
            </a>
          </li>
          <li className={activeTab === 'stats' ? 'is-active' : ''}>
            <a onClick={() => setActiveTab('stats')}>
              <span>Statystyki</span>
            </a>
          </li>
        </ul>
      </div>

      {activeTab === 'opinions' ? (
        <>
          <div className="columns is-multiline mb-4">
            <div className="column is-3">
              <div className="field">
                <label className="label">Data początkowa</label>
                <div className="control">
                  <input
                    type="date"
                    className="input"
                    value={startDate.toISOString().split('T')[0]}
                    onChange={(e) => handleDateChange(new Date(e.target.value), true)}
                  />
                </div>
              </div>
            </div>

            <div className="column is-3">
              <div className="field">
                <label className="label">Data końcowa</label>
                <div className="control">
                  <input
                    type="date"
                    className="input"
                    value={endDate.toISOString().split('T')[0]}
                    onChange={(e) => handleDateChange(new Date(e.target.value), false)}
                  />
                </div>
              </div>
            </div>

            <div className="column is-3">
              <div className="field">
                <label className="label">Rodzaj zgłoszenia</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={selectedIssueType}
                      onChange={(e) => setSelectedIssueType(e.target.value)}
                    >
                      <option value="">Wszystkie</option>
                      {Array.from(new Set(opinions
                        .map(o => o.issue_type)
                        .filter(Boolean)))
                        .map(type => (
                          <option key={type} value={type}>
                            {type}
                          </option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="column is-3">
              <div className="field">
                <label className="label">Status QA</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={selectedQAStatus}
                      onChange={(e) => setSelectedQAStatus(e.target.value as QAStatus)}
                    >
                      <option value="">Wszystkie</option>
                      {Object.entries(qaStatusLabels).map(([value, label]) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="table-container">
            <table className="table is-fullwidth is-striped is-hoverable">
              <thead>
                <tr>
                  <th>Nr sieci</th>
                  <th>Komentarz</th>
                  <th>Data</th>
                  <th>Typ problemu</th>
                  <th>Status QA</th>
                </tr>
              </thead>
              <tbody>
                {opinions.map((opinion) => (
                  <tr key={`${opinion.network_id}-${opinion.rated_at}-${opinion.rate_comment}`}>
                    <td>{opinion.network_id}</td>
                    <td>{opinion.rate_comment}</td>
                    <td>{new Date(opinion.rated_at).toLocaleDateString('pl-PL', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit'
                    })}</td>
                    <td>
                      <div className="select">
                        <select
                          value={opinion.issue_type || ''}
                          onChange={(e) => {
                            const index = opinions.indexOf(opinion);
                            handleUpdateOpinion(index, e.target.value);
                          }}
                        >
                          <option value="">Wybierz typ</option>
                          {Array.from(new Set(opinions
                            .map(o => o.issue_type)
                            .filter(Boolean)))
                            .map(type => (
                              <option key={type} value={type}>
                                {type}
                              </option>
                            ))
                          }
                        </select>
                      </div>
                    </td>
                    <td>
                      <div className="select">
                        <select
                          value={opinion.qa_status || ''}
                          onChange={(e) => {
                            const index = opinions.indexOf(opinion);
                            handleUpdateQAStatus(index, e.target.value as QAStatus);
                          }}
                        >
                          <option value="">Wybierz status</option>
                          {Object.entries(qaStatusLabels).map(([value, label]) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className="box">
          <OpinionsStats 
            opinions={filteredOpinions}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      )}
    </div>
  );
}; 