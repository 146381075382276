export const qaStatuses = [
  'new',
  'in_progress',
  'to_analize',
  'no_bug',
  'not_relevant',
  'to_ux_ui',
  'business',
  'resolved'
] as const;

export type QAStatus = typeof qaStatuses[number];

export const qaStatusLabels: Record<QAStatus, string> = {
  new: 'Nowy',
  in_progress: 'W trakcie',
  to_analize: 'Do analizy',
  no_bug: 'Nie jest błędem',
  not_relevant: 'Nie dotyczy aplikacji',
  to_ux_ui: 'Do UX/UI',
  business: 'Decyzja biznesowa',
  resolved: 'Rozwiązany'
};

export const qaStatusColors: Record<QAStatus, string> = {
  new: 'blue',
  in_progress: 'yellow',
  to_analize: 'orange',
  no_bug: 'green',
  not_relevant: 'gray',
  to_ux_ui: 'purple',
  business: 'cyan',
  resolved: 'teal'
}; 
